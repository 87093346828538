import { Tab } from "@mui/base";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableHead,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { gql, useLazyQuery } from "@apollo/client";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";
import { CustomButton } from "../../hoc/PaletteComponent";
import ImagePreview from "../Vehicles/ImagePreview";
import FileSaver from "file-saver";
import axios from "axios";

const GET_LOGS_BY_ID = gql`
  query ($loginId: Int, $ticketId: String) {
    getAllTicketDetails(loginId: $loginId, ticketId: $ticketId) {
      code
      message
      logs {
        id
        loginId
        requestId
        vin
        otp
        request_reason
        request_status
        ticket_status
        ticket_reason
        otpSentTime
        createdOn
        service_user_id
        categoryId
        categoryName
        subCategoryId
        subCategoryName
        uploadFileName
        clIssuedOn
        vahIssuedOn
      }
    }
  }
`;

const GET_DOWNLOAD_URL = gql`
  query ($bucketName: String!, $filename: String!) {
    getDownloadURL(bucketName: $bucketName, filename: $filename) {
      code
      remarks
      message
      url
    }
  }
`;
const ViewServiceLogs = ({
  open,
  columns,
  allLogs,
  handleVinLogs,
  selectedVin,
  oem,
  requestId,
  loginId,
  closeLogs,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  let [next, setNext] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [logs, setLogs] = useState();
  const [previewOpen, setPreviewOpen] = useState(null);
  const [previewUrl, setUrl] = useState(null);
  const [
    ServiceLogsDetails,
    { error: error, loading: loading, data: Details },
  ] = useLazyQuery(GET_LOGS_BY_ID, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    // onError: ({ graphQLErrors, networkError }) => {
    //   if (networkError) {
    //     props.setMessage(`Message: ${networkError}`);
    //     props.setNetworkError(true);
    //     props.setSnackbar(true);
    //   }

    //   if (graphQLErrors) {
    //     graphQLErrors.forEach(({ message, locations, path }) => {
    //       props.setMessage(`Message: ${message}`);
    //       props.setNetworkError(true);
    //       props.setSnackbar(true);
    //     });
    //   }
    //   handleClose();
    // },
    onCompleted: async (result) => {
      setLogs(result?.getAllTicketDetails?.logs || []);
      //   setTotalCount(result.getKycLogs.totalCount);
    },
  });
  const [getPreviewUrl, { data: previeUrl }] = useLazyQuery(GET_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    // onError: ({ graphQLErrors, networkError }) => {
    //   if (networkError) {
    //     props.setMessage(`Message: ${networkError}`);
    //     props.setNetworkError(true);
    //     props.setSnackbar(true);
    //   }
    //   if (graphQLErrors) {
    //     graphQLErrors.forEach(({ message, locations, path }) => {
    //       props.setMessage(`Message: ${message}`);
    //       props.setNetworkError(true);
    //       props.setSnackbar(true);
    //     });
    //   }
    // },
  });

  const DownloadPreview = (filename) => {
    const fileExtension = filename.substring(filename.lastIndexOf(".") + 1);
    console.log(fileExtension, "fileExtension");
    getPreviewUrl({
      variables: {
        // bucketName: "vecv-documents",
        bucketName:  process.env.REACT_APP_AZURE_DOCUMENTS_CONTAINER,
        filename: filename,
      },
      onCompleted: async (result) => {
        console.log(result);
        //   setPreviewOpen(true);
        //   setUrl(result.getDownloadURL.url);
        const res = await axios({
          url: result.getDownloadURL.url,
          method: "GET",
          headers: { Accept: "application/vnd.ms-excel" },
          responseType: "blob", // important
        });

        FileSaver.saveAs(new Blob([res.data]), `Attachment.${fileExtension}`);
      },
    });
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };
  useEffect(() => {
    ServiceLogsDetails({
      variables: {
        ticketId: requestId,
        loginId: loginId,
      },
    });
  }, [requestId, loginId]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      {/* <ImagePreview
        previewOpen={previewOpen}
        handlePreviewClose={handlePreviewClose}
        url={previewUrl}
      /> */}
      <DialogTitle>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                Ticket Logs
              </Grid>
              <Grid item xs={3}>
                <Button style={{ float: "right" }} onClick={closeLogs}>
                  <CloseIcon
                    style={{
                      fill: "#2b2924",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {loading ? (
              <CustomCircularProgress />
            ) : logs?.length > 0 ? (
              <TableContainer style={{ height: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          align="center"
                          style={{
                            minWidth: 200,
                            font: "normal normal 600 15px/20px Nunito Sans",
                            backgroundColor: "#faf8f7",
                          }}
                          className={classes.tableHead}
                        >
                          {column.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs.map((row, index) => {
                      let createdDate = moment
                        .unix(row.createdOn)
                        .format("DD-MM-YYYY HH:mm:ss");
                      return (
                        <TableRow>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {next + (index + 1)}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.vin}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.requestId}
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.categoryName ? row.categoryName : "NA"}
                          </TableCell>
                         <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.subCategoryName ? row.subCategoryName : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.ticket_status ? row.ticket_status : "NA"}
                          </TableCell> */}
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.request_status ? row.request_status : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.request_reason ? row.request_reason : "NA"}
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.uploadFileName ? (
                              <CustomButton
                                variant="contained"
                                onClick={() =>
                                  DownloadPreview(row.uploadFileName)
                                }
                              >
                                Download
                              </CustomButton>
                            ) : (
                              "NA"
                            )}
                          </TableCell> */}
                          {/* <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.clIssuedOn ? (moment.unix(row.clIssuedOn).format("DD-MM-YYYY HH:mm:ss")) : "NA"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {row.vahIssuedOn ? (moment.unix(row.vahIssuedOn).format("DD-MM-YYYY HH:mm:ss")) : "NA"}
                          </TableCell> */}
                          <TableCell
                            align="center"
                            className={classes.tableContent}
                          >
                            {createdDate}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NoData />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ViewServiceLogs;
