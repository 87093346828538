import React, { useState, useEffect, useCallback } from "react";
import useStyles from "./styles";
import {
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import SimMaxWidthDialog from "./editSim";
import axios from "axios";
import moment from "moment";

// components
import { client2 } from "../tables/client";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import Sim_Upload_Sample from "../../images/Sim-Sample-Upload.xlsx";
import SearchIcon from "@mui/icons-material/Search";
import SimBulkUploadDialog from "../charts/BulkSimUpload.jsx";

import { debounce } from "throttle-debounce";
import {
  CustomCircularProgress,
  CustomDialogBox,
  NoData,
  ValidateButton,
} from "../../hoc/CustomComponents";
import {
  ColorButton,
  ColorOutlinedButton,
  ColorSampleButton,
  CustomButton,
} from "../../hoc/PaletteComponent";
import Plans from "./Plans";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import FileSaver from "file-saver";

// import { NoData } from "../compaign/NoData";

const getFeatureAccess = gql`
  query ($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;

const GET_UPLOAD_URL = gql`
  mutation ($fileExtension: String!) {
    getPublicUploadURL(fileExtension: $fileExtension) {
      bucketName
      filename
      publicUploadURL
    }
  }
`;

const ACTIVATE_SIM = gql`
  mutation ($iccid: String!, $planName: String!) {
    activateSim(iccid: $iccid, planName: $planName) {
      status
      remarks
      generatedAt
      simStatus {
        status
        message
      }
    }
  }
`;
const DEACTIVATE_SIM = gql`
  mutation ($iccid: String!) {
    deactivateSim(iccid: $iccid) {
      code
      status
      message
    }
  }
`;

const getSIMData = gql`
  query ($OEMId: Int!, $id: Int, $limit: Int!, $offset: Int!,$search:SimSearchInput) {
    getSIMInventory(OEMId: $OEMId, id: $id, limit: $limit, offset: $offset, search: $search) {
      totalCount
      rows {
        id
        tcuInventoryId {
          id
          oem {
            id
            oemName
            oemCode
            oemEmail
            phoneNumber
            logo
          }
          tcu {
            id
            vendorCode
            tcuName
            ownerId
            tcu_code
          }
          imei
          serialNumber
          owner
        }
        simVendorId {
          id
          oemId {
            id
            oemName
            oemCode
            oemEmail
            phoneNumber
            logo
          }
          vendorName
          vendorCode
          contactName
          contactNumber
          vendorType
          simApiId
          ownerId
        }
        iccid
        profileCount
        simDetails {
          id
          simInventoryId
          primaryProvider
          secondaryProvider
          msisdn1
          msisdn2
          activatedOn
          expiredOn
          validity
          plan
          whitelistedIps
          whitelistedNumbers
          aadharNo
        }
        ownerId
        activation_status
        status
        simApi {
          id
          apiProviderName
          apiProviderUrl
        }
      }
    }
  }
`;

const excelFileUpload = gql`
  mutation excelFileUpload(
    $fileInfo: FileUploadInput!
    $commonInput: SimCommonInput!
  ) {
    excelFileUpload(fileInfo: $fileInfo, commonInput: $commonInput) {
      failedToUpload
      totalExcelDataRecords
      totalDuplicateRecords
      successfullyUploaded
      SimfailedUploadList
    }
  }
`;

const EXCEL_DOWNLOAD = gql`
  mutation ($oemId: Int!, $downloadFor: String!) {
    excelFileDownload(oemId: $oemId, downloadFor: $downloadFor) {
      downloadUrl
    }
  }
`;
const SimUploadcolumns = [
  "IMEI",
  "SIM_Provider_Code",
  "Iccid",
  "MSISDN1",
  "MSISDN2",
  "Tsp1",
  "Tsp2",

  "WhiteListed_Ips",
  "WhiteListed_phone",
  "error",
];

const columns = [
  {
    label: "Sr.No",
    minWidth: 100,
    align: "center",
  },
  {
    id: "device_manuf_code",
    label: "Device Manuf Code",
    minWidth: 170,
    align: "center",
  },
  {
    id: "device_model_code",
    label: "Device Model Code",
    minWidth: 170,
    align: "center",
  },
  {
    id: "device_model",
    label: "Device Model",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "device_imei",
    label: "Device IMEI",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "device_serial",
    label: "Device Serial",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "sim_provider_code",
    label: "SIM Provider",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "iccid",
    label: "ICCID",
    minWidth: 200,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: "kyc_details",
  //   label: "KYC Details",
  //   minWidth: 170,
  //   align: "center",
  //   format: (value) => value.toFixed(2),
  // },
  {
    id: "activation_type",
    label: "Activation Type",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "activated_on",
    label: "Activated On",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "valid_upto",
    label: "Valid Upto",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "msisdn1",
    label: "MSISDN 1",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "msisdn2",
    label: "MSISDN 2",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "whitelisted_ips",
    label: "WhiteListed IPS",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "whitelisted_phone",
    label: "WhiteListed Phone",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "sim_status",
    label: "Sim Status",
    minWidth: 170,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  // {
  //   id: "sim_act_deact",
  //   label: "Sim Act/Deact",
  //   minWidth: 170,
  //   align: "center",
  //   format: (value) => value.toFixed(2),
  // },
];

function Charts(props) {
  var theme = useTheme();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [editId, setEditId] = React.useState();
  const [oemId, setOemId] = React.useState();
  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [excelError, setExcelerror] = useState();
  const [errorOpen, setErrorOpen] = useState(false);
  const [excelData, setexcelData] = useState([]);
  let [next, setNext] = useState(0);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [ecuId, setEcuId] = useState(null);
  const [simBulkUploadResponse, setSimBulkUploadResponse] = useState(null);
  const [openPlan, setPlan] = useState(false);
  const [allPlans, setAllPlans] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [deactivateDialog, setDeactivateDialog] = useState(false);
  const [SimIccid, setSimIccid] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [SIMData, setSIMData] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [allData, setAlldata] = useState();
  const [searchVal, setSearchVal] = React.useState();

  let id;
  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
  } else {
    id = parseInt(localStorage.getItem("featureId"));
  }

  useEffect(() => {
    if (props.oem) {
      getSIMInventory({
        variables: {
          OEMId: props.oem,
          limit: rowsPerPage,
          offset: page,
        },
      });
    }
  }, [props.oem]);
  //Call the Feature Access Permission on page load
  useEffect(() => {
    getAccess({
      variables: {
        featureId: id,
      },
    });

    getSIMInventory({
      variables: {
        OEMId: props.oem,
        limit: rowsPerPage,
        offset: page,
      },
    });
    //removed previous snackbar state
    props.setSnackbar(false);
  }, []);

  const newSimClickHandle = () => {
    setIsModelOpen(true);
  };

  const handleSimModelClose = () => {
    setIsModelOpen(false);
    setSimBulkUploadResponse(null);
  };

  const [getAccess, { loading, error, data }] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });

  /**
   * @summary to fetch  sim Inventory data with limit and offset
   */
  const [
    getSIMInventory,
    { loading: SIMLoading, error: SIMError, data: SIMDatas },
  ] = useLazyQuery(getSIMData, {
    fetchPolicy: "network-only",
    onCompleted: async (result) => {
      console.log("resut", SIMDatas.getSIMInventory.rows);
      setSIMData(SIMDatas.getSIMInventory.rows);
      setTotalCount(result.getSIMInventory?.totalCount);
      console.log("result ----", result);
      setSearchVal("");
      // await getAllSIMInventory({
      //   variables: {
      //     OEMId: props.oem,
      //     limit: result.getSIMInventory?.totalCount,
      //     offset: 0,
      //   },
      // });
    },
  });

  /**
   * @summary to fetch  sim Inventory
   */
  //To disable the loading of page  while polling
  const [
    getSIMInventory1,
    {
      loading: SIMPollingLoading,
      error: SIMPollingError,
      data: SIMPollingData,
    },
  ] = useLazyQuery(getSIMData, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      setSIMData(result.getSIMInventory.rows);
      setTotalCount(result.getSIMInventory?.totalCount);
    },
  });

  /**
   * @summary to fetch all sim Inventory data without limit and offset
   */
  // const [getAllSIMInventory, { data: AllSIMDatas, loading: allSimLoading }] =
  //   useLazyQuery(getSIMData, {
  //     fetchPolicy: "network-only",
  //     onCompleted: (result) => {
  //       setAlldata(result.getSIMInventory.rows);
  //     },
  //   });

  const handleChangePage = (event, newPage) => {
    setSearchVal("");
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    getSIMInventory({
      variables: {
        OEMId: props.oem,
        limit: rowsPerPage,
        offset: newPage,
      },
    });
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (id, oemId) => {
    setDelete(false);
    setIsOpen(true);
    setEditId(id);
    setOemId(props.oem);
  };

  const handleDelete = (id) => {
    setIsOpen(true);
    setEditId(id);
    setOemId(oemId);
    setDelete(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setSearchVal("");
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    getSIMInventory({
      variables: {
        OEMId: props.oem,
        limit: event.target.value,
        offset: 0,
      },
    });
  };

  const [
    excelUpload,
    { loading: excelLoading, error: excelerror, data: exceldata },
  ] = useMutation(excelFileUpload, {
    fetchPolicy: "network-only",

    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setExcelError(`Message: ${networkError}`);
        props.closeDialog(false);
        props.setExcelData(null);
        props.setUpload(false);
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setExcelError(`Message: ${message}`);
          props.closeDialog(false);
          props.setExcelData(null);
          props.setUpload(true);
          props.setMessage("File headers are not Same as a given template!");
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: async (ressss) => {
      if (ressss?.excelFileUpload?.totalExcelDataRecords == 0) {
        props.setNetworkError(true);
        props.setUpload(false);
        props.setMessage("Empty file is not allowed");
        props.setSnackbar(true);
      } else {
        props.setColumns(SimUploadcolumns);
        props.setTitle("Sim Uploads");
        props.setMessage(
          "The file data has been uploaded. Click on 'View' to view the result."
        );
        props.setExcelData(ressss.excelFileUpload);
        props.setNetworkError(false);
        props.setUpload(true);
        props.setSnackbar(true);

        await getSIMInventory({
          variables: {
            OEMId: props.oem,
            limit: rowsPerPage,
            offset: page,
          },
        });
      }
    },
  });

  /**
   * @summary Fetches a url for uploading a file and uploads the file
   */
  const onUpload = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (file) {
      props.setMessage("");

      props.setExcelError("");
      props.closeDialog(false);
      props.setExcelData(null);
      props.setNetworkError(false);
      props.setUpload(false);
      props.setMessage(`Uploading....`);
      props.setSnackbar(true);
      if (validity.valid) {
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        if (fileExtension !== "xlsx") {
          props.setMessage("Only xlsx files are allowed");
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleClose();
        } else {
          const response = await client2.mutate({
            mutation: GET_UPLOAD_URL,
            variables: {
              fileExtension,
            },
            errorPolicy: "all",
          });
          if (response.data && response.data.getPublicUploadURL) {
            const url = response.data.getPublicUploadURL.publicUploadURL;
            await axios.put(url, file, {
              headers: {
                'x-ms-blob-type': 'BlockBlob',
              },
              onUploadProgress: (data) => {
                //Set the progress value to show the progress bar
                let percentage = Math.round((100 * data.loaded) / data.total);
                props.setSnackbar(false);
                // handleClose();
              },
            });
            setSimBulkUploadResponse(response);
          } else {
            props.setMessage("Something went wrong,Please Try again!");
            props.setNetworkError(true);
            props.setSnackbar(true);
            handleClose();
          }
        }
      }
    } else {
      alert("No file selected");
    }
  };

  /**
   * @summary closes the snackbar
   */
  const handleClose = () => {
    setTimeout(() => {
      props.setSnackbar(false);
    }, 7000);
  };

  /**
   * @summary api call to activate the sim
   */
  const [
    activateSim,
    { loading: activeLoading, error: activeError, data: simActiveData },
  ] = useMutation(ACTIVATE_SIM, {
    onError: ({ graphQLErrors, networkError }) => {
      console.log(activeError, "activeError");
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleClose();
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleClose();
        });
      }
    },
    onCompleted: async (result) => {
      getPollingData();
      if (simActiveData && simActiveData.activateSim.status == 200) {
        props.setMessage("Sim Activation Requested successfully!");
      } else {
        props.setMessage("Sim Activation Failed");
      }
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleClose();
    },
  });

  /**
   * @summary api call to deactivate the sim
   */
  const [
    deactivateSimDetail,
    {
      loading: deactivateLoading,
      error: deactivateError,
      data: deactivateData,
    },
  ] = useMutation(DEACTIVATE_SIM, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleClose();
      }
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
          handleClose();
        });
      }
    },
    onCompleted: async (result) => {
      console.log(result);
      getPollingData();
      if (deactivateData.deactivateSim.code == 200) {
        props.setMessage("Sim Deactivation Requested successfully!");
      } else {
        props.setMessage(`Sim Deactivation Request Failed`);
      }
      props.setNetworkError(true);
      props.setSnackbar(true);
      handleClose();
    },
  });

  /**
   * @summary opens the model to select plan
   */
  const selectPlan = async (id) => {
    setSimIccid(id);
    setPlan(true);
  };

  /**
   * @summary handles the plan selection
   */
  const handlePlanSelect = (id, plan) => {
    if (!plan) {
      setSelectedPlan("");
    } else {
      setSelectedPlan(plan.planName);
    }
  };

  /**
   * @summary close the model to select plan
   */
  const cancelPlan = () => {
    setPlan(false);
  };

  /**
   * @summary activate the sim
   */
  const submitSimActivation = async () => {
    //api call to activate sim
    console.log(SimIccid, "SimIccid", selectedPlan, "plan");
    setPlan(false);
    await activateSim({
      variables: {
        iccid: SimIccid,
        planName: selectedPlan,
      },
    });
    setSelectedPlan(null);
  };

  /**
   * @summary opens the confirm model to Deactivate sim
   */
  const handleDeactivated = async (id) => {
    setSimIccid(id);
    setDeactivateDialog(true);
  };

  /**
   * @summary closes the deactivation confirm model
   */
  const cancelDeactivate = () => {
    setDeactivateDialog(false);
  };

  /**
   * @summary calls the deactivatesim api
   */
  const deactivateSim = async () => {
    //call the api to deactivate sim
    deactivateSimDetail({
      variables: {
        iccid: SimIccid,
      },
    });

    setDeactivateDialog(false);
  };

  const handleAddSimClick = async () => {
    const { bucketName, filename } =
      simBulkUploadResponse.data.getPublicUploadURL;

    let response;
    props.setUpload(true);
    response = await excelUpload({
      variables: {
        fileInfo: {
          uploadFor: "SIMDetailsUpload",
          bucketName,
          fileName: filename,
        },
        commonInput: {
          oemId: props.oem,
          ecuId: 6,
        },
      },
    });

    handleSimModelClose();
    setSimBulkUploadResponse(null);
  };

  // const getData = async () => {
  //   await getSIMInventory({
  //     variables: {
  //       OEMId: props.oem,
  //       limit: rowsPerPage,
  //       offset: page,
  //     },
  //   });
  // };

  /**
   * @summary handles Search value
   */
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }
  
  /**
   * @summary returns the filtered array for search
   */
  const debounceSearchRender = useCallback(debounce(async (input) => {
    console.log(props, totalCount)
    if (input) {
      await getSimDetails({
        variables: {
          OEMId: props.oem,
          limit: totalCount,
          offset: 0,
          search: {
            iccid: input.trim(),
          },
        },
      });

    } else {
      await getSimDetails({
        variables: {
          OEMId: props.oem,
          limit: rowsPerPage,
          offset: page,
        },
      });
    }
  }, 1000), [props.oem, totalCount, rowsPerPage, page]);
 
  const handleSearchChange = (event) => {
    const input = event.target.value;
    setSearchVal(input);
    debounceSearchRender(input);
  };

  // /**
  //  * @summary handles Search value
  //  */
  // const handleSearchChange = (event) => {
  //   console.log(event.target.value, "vall");
  //   // setFilterText(event.target.value);
  //   setSearchVal(event.target.value);
  //   setTimeout(debounceSearchRender(event.target.value), 2000);
  // };

  // /**
  //  * @summary returns the filtered array for search
  //  */
  // const debounceSearchRender = debounce(1000, async (event) => {
  //   if (event) {
  //     // const filteredRows = allData.filter((row) => {
  //     //   const searchText = event.toLowerCase().trim();

  //     //   return (
  //     //     (row.tcuInventoryId.serialNumber &&
  //     //       row.tcuInventoryId.serialNumber
  //     //         .toLowerCase()
  //     //         .includes(searchText)) ||
  //     //     (row.tcuInventoryId.imei &&
  //     //       row.tcuInventoryId.imei.toLowerCase().includes(searchText)) ||
  //     //     (row.status && row.status.toLowerCase().includes(searchText)) ||
  //     //     (row.iccid && row.iccid.toLowerCase().includes(searchText)) ||
  //     //     (row.simDetails.aadharNo &&
  //     //       row.simDetails.aadharNo.toLowerCase().includes(searchText))
  //     //   );
  //     // });
  //     // setSIMData(filteredRows);
  //     // console.log(filteredRows, "filteredRows");

  //     await getSimDetails({
  //       variables: {
  //         OEMId: props.oem,
  //         limit: totalCount,
  //         offset: 0,
  //         search: {
  //           iccid: event.trim(),
  //         },
  //       },
  //     });

  //   } else {
  //     await getSimDetails({
  //       variables: {
  //         OEMId: props.oem,
  //         limit: rowsPerPage,
  //         offset: page,
  //       },
  //     });
  //   }
  // });

  /**
   * @summary api call to fetch sim details
   */
  const [getSimDetails, { data: sim }] = useLazyQuery(getSIMData, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      setSIMData(result.getSIMInventory.rows);
      setTotalCount(result.getSIMInventory?.totalCount);
    },
  });

  const getPollingData = async () => {
    await getSIMInventory1({
      variables: {
        OEMId: props.oem,
        limit: rowsPerPage,
        offset: page,
      },
    });
  };

  // useEffect(() => {
  //   const timer = setInterval(getPollingData, 4000);
  //   return () => clearInterval(timer);
  // }, [props.oem, page, rowsPerPage]);

  const [getDownloadUrl, { data: url, loading: downloading }] = useMutation(
    EXCEL_DOWNLOAD,
    {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: ${message}`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
      },
      onCompleted: async (result) => {
        if (result.excelFileDownload.downloadUrl) {
          const res = await axios({
            url: result.excelFileDownload.downloadUrl,
            method: "GET",
            headers: { Accept: "application/vnd.ms-excel" },
            responseType: "blob", // important
          });

          FileSaver.saveAs(new Blob([res.data]), "SimManagement.xlsx");
        } else {
          props.setMessage("Something went wrong,Please Try again!");
          props.setSnackbar(true);
          handleClose();
        }
      },
    }
  );

  const DownloadExcel = () => {
    getDownloadUrl({
      variables: {
        oemId: props.oem,
        downloadFor: "SIM_MANAGER",
      },
    });
  };
  return (
    <>
      {isModelOpen ? (
        <SimBulkUploadDialog
          isModelOpen={isModelOpen}
          handleSimModelClose={handleSimModelClose}
          oemId={props.oem}
          upload={onUpload}
          setEcuId={setEcuId}
          ecuId={ecuId}
          handleAddSimClick={handleAddSimClick}
          simBulkUploadResponse={simBulkUploadResponse}
          snackbarOpen={props.open}
          excelLoading={excelLoading}
        />
      ) : null}
      <Plans
        selectedPlan={selectedPlan}
        openPlan={openPlan}
        cancelPlan={cancelPlan}
        submitPlan={submitSimActivation}
        handlePlanSelect={handlePlanSelect}
      />
      <CustomDialogBox
        open={deactivateDialog}
        content={`Are you sure you want to Deactivate This Sim (ICCID:${SimIccid})?`}
        negativeResponseHandler={cancelDeactivate}
        negativeResponseButtonText="Cancel"
        positiveResponseHandle={deactivateSim}
        positiveResponseButtonText="Deactivate"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ backgroundColor: "#F3F3F3 " }}>
          <Typography
            style={{ marginTop: "2px", marginBottom: "4px" }}
            variant="h4"
          >
            SIM Management
          </Typography>
        </Grid>

        {/* {loading ? (
          <CircularProgress />
          
        ) : ( */}
        <>
          <Grid
            container
            style={{ backgroundColor: "#FFFFFF",marginLeft:'16px'}}
          >
            {readPermission ? (
              <>
                <Grid item xs={6} style={{ padding: "8px" }}>
                  <TextField
                    placeholder="Search Number"
                    variant="outlined"
                    // multiline
                    value={searchVal}
                    onChange={(e) => handleSearchChange(e)}
                    style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ paddingLeft: "3px" }} />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.textfield}
                  />
                  {downloading ? (
                    <CircularProgress style={{ color: "#57B793" }} />
                  ) : (
                    <IconButton
                      title="Download Excel"
                      aria-label="Download Excel"
                      //
                      onClick={DownloadExcel}
                      disabled={totalCount == 0}
                    >
                      <CloudDownloadIcon
                        style={{ fill: "#57B793", fontSize: 30 }}
                      />
                    </IconButton>
                  )}
                </Grid>
                {createPermission && (
                  <Grid
                    item
                    xs={6}
                    style={{ paddingTop: "8px", paddingRight: "40px" }}
                  >
                    {/* <div> */}
                    <Grid container spacing={2} justifyContent="flex-end">
                      <Grid item xs={4}>
                        <ColorSampleButton
                          href={Sim_Upload_Sample}
                          // variant="outlined"
                          // className={classes.sampleButton}
                          download="SIM-Upload-Sample"
                        // style={{ float: "right" }}
                        //onClick={() => setSnackbar("Downloading")}
                        >
                          <Typography style={{ color: "white" }}>
                            {" "}
                            Sample File
                          </Typography>
                        </ColorSampleButton>
                      </Grid>
                      <Grid item xs={4}>
                        <ColorButton
                          // className={classes.button}
                          onClick={() => newSimClickHandle()}
                        >
                          <Typography> Add New Sim</Typography>
                        </ColorButton>
                        {/* </label> */}
                      </Grid>
                    </Grid>
                    {/* </div> */}
                  </Grid>
                )}
              </>
            ) : null}

            <Grid item xs={12} style={{maxHeight:'70vh'}}>
              {SIMLoading  ? (
                <CustomCircularProgress />
              ) : (
                <React.Fragment>
                  {SIMData && SIMData.length > 0 ? (
                    <Paper>
                      <TableContainer
                        className={classes.container}
                        style={{ maxHeight: "calc(100vh - 300px)" }}
                      >
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => (
                                <>
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                      minWidth: column.minWidth,
                                      font: "normal normal 600 15px/20px Nunito Sans",
                                      letterSpacing: "0px",
                                      color: "#212121",
                                      borderRightWidth: 1,
                                      borderColor: "#E8E8E8",
                                      borderTop: "1px solid #E8E8E8",
                                      backgroundColor: "#faf8f7",
                                    }}
                                    className={classes.headerFont}
                                  >
                                    {column.label}
                                  </TableCell>
                                </>
                              ))}
                              {updatePermission || deletePermission ? (
                                <TableCell
                                  align="center"
                                  style={{
                                    font: "normal normal 600 15px/20px Nunito Sans",
                                    letterSpacing: "0px",
                                    color: "#212121",
                                    borderRightWidth: 1,
                                    borderColor: "#E8E8E8",
                                    borderTop: "1px solid #E8E8E8",
                                    minWidth: 170,
                                    backgroundColor: "#faf8f7",
                                    padding:'8px'
                                  }}
                                >
                                  Action
                                </TableCell>
                              ) : null}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* {SIMLoading ? <CircularProgress /> : null} */}
                            {SIMData.map((row, index) => {
                              let expDate = row.simDetails.expiredOn
                                ? moment
                                  .unix(row.simDetails.expiredOn / 1000)
                                  .format("DD-MM-YYYY")
                                : null;
                              // let expDate = row.simDetails.validity
                              //   ? moment(expDate1)
                              //       .add(row.simDetails.validity, "M")
                              //       .format("DD-MM-YYYY")
                              //   : null;

                              const actDate = row.simDetails.activatedOn
                                ? moment
                                  .unix(row.simDetails.activatedOn / 1000)
                                  .format("DD-MM-YYYY")
                                : null;
                              return (
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {next + (index + 1)}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                    style={{
                                      borderRightWidth: 1,
                                    }}
                                  >
                                    {row.tcuInventoryId.tcu.vendorCode}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.tcuInventoryId.tcu.tcu_code}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.tcuInventoryId.tcu.tcuName}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.tcuInventoryId.imei}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.tcuInventoryId.serialNumber}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.simVendorId.vendorCode}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.iccid}
                                  </TableCell>
                                  {/* <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.simDetails.aadharNo}
                                  </TableCell> */}
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.simDetails
                                      ? row.activation_status
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    className={classes.tableContent}
                                  >
                                    {row.simDetails ? (
                                      <div style={{ display: "flex" }}>
                                        <div style={{ marginRight: "10px" }}>
                                          <Tooltip
                                            placement="top-end"
                                            title="TSP 1"
                                          >
                                            <Chip
                                              variant="outlined"
                                              size="small"
                                              label={`${
                                                row.simDetails.primaryProvider
                                              }, ${
                                                actDate === null
                                                  ? "N/A"
                                                  : actDate
                                              }`}
                                              color="primary"
                                            />
                                          </Tooltip>
                                        </div>
                                        <Tooltip
                                          placement="top-end"
                                          title="TSP 2"
                                        >
                                          <Chip
                                            variant="outlined"
                                            size="small"
                                            label={`${
                                              row.simDetails.secondaryProvider
                                            }, ${
                                              actDate === null ? "N/A" : actDate
                                            }`}
                                            color="secondary"
                                          />
                                        </Tooltip>
                                      </div>
                                    ) : (
                                      "N/A"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.simDetails ? (
                                      <div style={{ display: "flex" }}>
                                        <div style={{ marginRight: "10px" }}>
                                          <Tooltip
                                            placement="top-end"
                                            title="TSP 1"
                                          >
                                            <Chip
                                              variant="outlined"
                                              size="small"
                                              label={`${
                                                row.simDetails.primaryProvider
                                              }, ${
                                                expDate === null
                                                  ? "N/A"
                                                  : expDate
                                              }`}
                                              color="primary"
                                            />
                                          </Tooltip>
                                        </div>
                                        <Tooltip
                                          placement="top-end"
                                          title="TSP 2"
                                        >
                                          <Chip
                                            variant="outlined"
                                            size="small"
                                            label={`${
                                              row.simDetails.secondaryProvider
                                            }, ${
                                              expDate === null ? "N/A" : expDate
                                            }`}
                                            color="secondary"
                                          />
                                        </Tooltip>
                                      </div>
                                    ) : (
                                      "N/A"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.simDetails
                                      ? row.simDetails.msisdn1
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.simDetails
                                      ? row.simDetails.msisdn2
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.simDetails
                                      ? row.simDetails.whitelistedIps
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.simDetails
                                      ? row.simDetails.whitelistedNumbers
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.status ? row.status : "N/A"}
                                  </TableCell>
                                  {/* <TableCell
                                    align="center"
                                    className={classes.tableContent}
                                  >
                                    {row.status == "REGISTERED" ||
                                    row.status == "DEACTIVATED" ||
                                    row.status == "ACTIVATION_REQUESTED" ||
                                    row.status == "FAILED_TO_ACTIVATE" ? (
                                      <CustomButton
                                        variant="contained"
                                        disabled={
                                          row.status == "ACTIVATION_REQUESTED"
                                        }
                                        onClick={() => selectPlan(row.iccid)}
                                      >
                                        Activate
                                      </CustomButton>
                                    ) : row.status == "ACTIVATED" ||
                                      row.status == "DEACTIVATION_REQUESTED" ? (
                                      <CustomButton
                                        variant="contained"
                                        disabled={
                                          row.status ===
                                          "DEACTIVATION_REQUESTED"
                                        }
                                        onClick={() =>
                                          handleDeactivated(row.iccid)
                                        }
                                      >
                                        Deactivate
                                      </CustomButton>
                                    ) : null}
                                  </TableCell> */}
                                  {updatePermission || deletePermission ? (
                                    <TableCell
                                      align="center"
                                      className={classes.tableContent}
                                    >
                                      <Grid
                                        container
                                        style={{
                                          height: "2px",
                                        }}
                                        alignContent="center"
                                      >
                                        {updatePermission ? (
                                          <Grid item>
                                            <Button
                                              onClick={() =>
                                                handleOpen(
                                                  row.id,
                                                  row.tcuInventoryId.oem.id
                                                )
                                              }
                                            >
                                              <CreateIcon
                                                style={{
                                                  fill: "#2b2924",
                                                }}
                                              />
                                            </Button>
                                          </Grid>
                                        ) : null}
                                        {deletePermission ? (
                                          <Grid item>
                                            <Button
                                              onClick={() =>
                                                handleDelete(row.id)
                                              }
                                            >
                                              <DeleteIcon
                                                style={{
                                                  fill: "#2b2924",
                                                }}
                                              />
                                            </Button>
                                          </Grid>
                                        ) : null}
                                      </Grid>
                                    </TableCell>
                                  ) : null}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {SIMData ? (
                        <TablePagination
                          rowsPerPageOptions={[20,50, 100]}
                          component="div"
                          count={totalCount}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      ) : null}
                    </Paper>
                  ) : (
                    <NoData />
                  )}
                </React.Fragment>
              )}
            </Grid>
            {isOpen ? (
              <SimMaxWidthDialog
                isDialogOpened={isOpen}
                handleCloseDialog={() => {
                  setIsOpen(false);
                  setSearchVal("");
                  getSIMInventory({
                    variables: {
                      OEMId: props.oem,
                      limit: rowsPerPage,
                      offset: page,
                    },
                  });
                }}
                simInventoryId={editId}
                oemTcuInventoryId={oemId}
                isDelete={isDelete}
              />
            ) : null}
          </Grid>
        </>
        {/* )} */}
      </Grid>
    </>
  );
}

export default withSnackbar(Charts);
// ################################################################
