import { Button, Dialog, DialogContent, Grid, Typography, CircularProgress } from "@mui/material";
import {
  CustomSelect,
  CustomTextArea,
  CustomTextField,
} from "../../hoc/CustomComponents";
import CloseIcon from "@mui/icons-material/Close";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
// const allStatus = ["INPROGRESS", "RESOLVED"];
export default function AssignToL1({
  open,
  vin,
  requestId,
  handleCloseAssign,
  ticketStatus,
  handleTicketSubmit,
  handleOtp,
  otp,
  remark,
  handleRemarks,
  allStatus,
  category,
  handleTicketStatus,
  subCategory,
  handleCloseConfim,
  handleConfirmAssign,
  assignLoading,
}) {
  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      fullWidth={true}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h4">Assign To L1</Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  style={{ float: "right" }}
                  onClick={() => handleCloseConfim()}
                >
                  <CloseIcon
                    style={{
                      fill: "#2b2924",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomTextField value={vin} label="Vin" disabled={true} />
              </Grid>
              <Grid item xs={6}>
                <CustomTextField
                  value={requestId}
                  label="Request ID"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomSelect
                  label="Category"
                  placeholder="Select the Category"
                  value={category}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomSelect
                  label="Sub Category"
                  placeholder="Select the Sub Category"
                  value={subCategory}
                  disabled={true}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <CustomTextField
                  value={otp}
                  label="OTP"
                  placeholder="Enter OTP"
                  onChange={(e) => handleOtp(e.target.value)}
                />
              </Grid> */}
              <Grid item xs={6}>
                <CustomTextArea
                  value={remark}
                  label="Remarks"
                  placeholder="Remarks"
                  type="text"
                  onChange={(e) => {
                    handleRemarks(e.target.value);
                  }}
                  rows="3"
                />
              </Grid>
              {/* <Grid item xs={6}>
                <CustomTextField
                  variant="contained"
                  placeholder="Upload template"
                  label="Upload File*"
                  // disabled={true}
                  type="file"
                  onChange={upload}
                />
              </Grid> */}
            </Grid>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={2}>
                <ColorOutlinedButton
                  variant="outlined"
                  onClick={() => handleCloseConfim()}
                >
                  Cancel
                </ColorOutlinedButton>
              </Grid>
              <Grid item xs={2}>
              {assignLoading ? (
                <CircularProgress style={{ color: "#57B793" }} />
              ) : (
                <ColorButton
                  variant="outlined"
                  onClick={handleConfirmAssign}
                  //   disabled={
                  //     category == null ||
                  //     category == "" ||
                  //     subCategory == null ||
                  //     subCategory == "" ||
                  //     remark == "" ||
                  //     remark == null ||
                  //     filename == "" ||
                  //     filename == null
                  //   }
                  //   disabled={snackbarOpen}
                >
                  Submit
                </ColorButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
