import {
  Typography,
  Grid,
  InputAdornment,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TablePagination,
  Button,
} from "@mui/material";
import useStyles from "./styles";
import SearchIcon from "@mui/icons-material/Search";
import { ColorButton, CustomButton } from "../../hoc/PaletteComponent";
import React, { useEffect, useState, useCallback } from "react";
import AddTicket from "./AddTickets";
import UpdateTicket from "./updateTicket";
import ViewIssue from "./viewIssue";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { client2 } from "../tables/client";
import { minioClient } from "./minioUploadClient";
import axios from "axios";
// import { Tabs, Tab } from "@mui/material";
import withSnackbar from "../../components/Snackbar/withSnackbar";
import CreateIcon from "@mui/icons-material/Create";
import FormData from "form-data";
import { debounce } from "throttle-debounce";
import FileSaver from "file-saver";

import {
  Paper
} from "@mui/material";

/**
 * @summary getAllVinImeiMapData schema
 */
const GET_ALL_TICKET_DATA = gql`
  query ($oemId: Int!, $limit: Int!, $offset: Int!,$ticketSearch: ticketSearchInput) {
    getAllTickets(oemId: $oemId, limit: $limit, offset: $offset, ticketSearch: $ticketSearch) {
      code
      remarks
      count
      data {
        id
        oemId
        category
        vin
        ticket_number
        contact_name
        contact_email
        subject
        issue
        comment
        attached_filename_url
        attached_screenshot_url
        status
        createdOn
        priority
        updatedOn
      }
    }
  }
`;

/**
 * @summary getPublicUploadURL schema
 */
const GET_UPLOAD_URL = gql`
  mutation ($fileExtension: String!,$bucketName: String) {
    getPublicUploadURL(fileExtension: $fileExtension,bucketName:$bucketName) {
      bucketName
      filename
      publicUploadURL
    }
  }
`;

/**
 * @summary getPublicDownloadURL schema
 */
const GET_DOWNLOAD_URL = gql`
  query ($bucketName: String!, $filename: String!) {
    getPublicDownloadURL(bucketName: $bucketName, filename: $filename)
  }
`;

/*
 * @summary ADD_TICKETS schema
 */
const ADD_TICKETS = gql`
  mutation (
    $oemId: Int!
    $category: String!
    $vin: String!
    $contact_name: String!
    $contact_email: String!
    $subject: String!
    $issue: String!
    $attached_filename_url: String
    $attached_screenshot_url: String
    $priority: String!
  ) {
    createTicket(
      oemId: $oemId
      category: $category
      vin: $vin
      contact_name: $contact_name
      contact_email: $contact_email
      subject: $subject
      issue: $issue
      attached_filename_url: $attached_filename_url
      attached_screenshot_url: $attached_screenshot_url
      priority: $priority
    ) {
      code
      message
    }
  }
`;

/**
 * @summary ADD_TICKETS schema
 */
const UPDATE_TICKETS = gql`
  mutation ($id: Int!, $oemId: Int!, $status: String!, $comment: String) {
    updateTicketStatus(
      id: $id
      oemId: $oemId
      status: $status
      comment: $comment
    ) {
      code
      message
    }
  }
`;

/**
 * @summary columns to show All mapped Data
 */

const GET_ALL_VIN = gql`
  query ($oemId: Int!, $limit: Int!, $offset: Int!) {
    getAllVinImeiMapData(oemId: $oemId, limit: $limit, offset: $offset) {
      code
      message
      mappedData {
        totalCount
        rows {
          id
          vin
        }
      }
    }
  }
`;

const getFeatureAccess = gql`
  query ($featureId: Int!) {
    getFeatureAccess(featureId: $featureId) {
      id
      userId
      featureId
      createPermission
      readPermission
      updatePermission
      deletePermission
    }
  }
`;
const columns = [
  {
    label: "Sr.No",
    minWidth: 100,
    align: "center",
  },
  {
    label: "Ticket Number",
    minWidth: 350,
    align: "center",
  },
  {
    label: "Category",
    minWidth: 350,
    align: "center",
  },
  {
    label: "VIN",
    minWidth: 200,
    align: "center",
  },
  {
    label: "Customer Name",
    minWidth: 350,
    align: "center",
  },
  {
    label: "Customer Email",
    minWidth: 170,
    align: "center",
  },
  {
    label: "Subject",
    minWidth: 170,
    align: "center",
  },
  {
    label: "Issue",
    minWidth: 170,
    align: "center",
  },
  {
    label: "Ticket Created",
    minWidth: 230,
    align: "center",
  },
  {
    label: "Status",
    minWidth: 170,
    align: "center",
  },
  {
    label: "Priority",
    minWidth: 170,
    align: "center",
  },
];

function TicketCreation(props) {
  const classes = useStyles();
  const [activeTab, setactiveTab] = useState(0);
  const [openAddTickets, setAddTickets] = useState(false);
  const [mappedData, setMappedData] = useState([]);
  const [allData, setAlldata] = useState([]);
  let [next, setNext] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [ticketNumber, setTicketNumber] = useState(null);
  const [customerName, setcustomerName] = useState(null);
  const [customerEmail, setcustomerEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [issue, setIssue] = useState(null);
  const [attached_filename_url, setAttachedfilenameurl] = useState(null);
  const [attached_screenshot_url, setAttachedscreenshoturl] = useState(null);
  const [priority, setpriority] = useState(null);
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [openIssue, setOpenIssue] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [status, setStatus] = useState(null);
  const [comment, setComment] = useState(null);
  const [category, setCategory] = useState(null);
  const [vin, setVin] = useState(null);
  const [roleId, setRoleId] = useState(props.token.roleId);
  const [fileType, setfileType] = useState(null);
  const [filename, setfilename] = useState(null);
  const [oemVin, setOemVin] = useState([]);
  const [searchVal, setSearchVal] = useState();
  const [readPermission, setReadPermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [previousStatus, setPreviousStatus] = useState(false);
  const [previousComment, setPreviousComment] = useState(false);
  const [disableButton, setDisabled] = useState(false); //for update submit button
  let vinArray = [];

  let id;

  if (typeof props.featureId != "undefined") {
    localStorage.setItem("featureId", props.featureId);
    id = props.featureId;
  } else if (parseInt(localStorage.getItem("featureId"))) {
    id = parseInt(localStorage.getItem("featureId"));
  } else {
    id = 12;
  }

  const [getAccess] = useLazyQuery(getFeatureAccess, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      console.log("resss----", result);
      if (result && result.getFeatureAccess) {
        result.getFeatureAccess.forEach((element) => {
          if (element.readPermission === 1) {
            setReadPermission(true);
          } else {
            setReadPermission(false);
          }
          if (element.createPermission === 1) {
            setCreatePermission(true);
          } else {
            setCreatePermission(false);
          }
          if (element.updatePermission === 1) {
            setUpdatePermission(true);
          } else {
            setUpdatePermission(false);
          }
          if (element.deletePermission === 1) {
            setDeletePermission(true);
          } else {
            setDeletePermission(false);
          }
        });
      }
    },
  });
  /**
   * @summary This handles the page change in pagination
   */
  const handleChangePage = async (event, newPage) => {
    setSearchVal("");
    setNext(newPage * rowsPerPage);
    setPage(newPage);
    await getAllTicketsData({
      variables: { oemId: props.oem, limit: rowsPerPage, offset: newPage },
    });
  };

  /**
   * @summary handles Search value
   */
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }
  
  /**
   * @summary returns the filtered array for search
   */
  const debounceSearchRender = useCallback(debounce(async (input) => {
    console.log(props)
    if (input) 
    {
      await getAllTicketsData({
        variables: {
          oemId: props.oem, limit: totalCount, offset: 0, ticketSearch: {
            ticketNumber: input.trim()
          }
        },
      });
    } else {
      await getAllTicketsData({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
    }
  }, 1000), [props.oem,totalCount, rowsPerPage, page]);
 
  const handleSearchChange = (event) => {
    const input = event.target.value;
    setSearchVal(input);
    debounceSearchRender(input);
  };

  // /**
  //  * @summary handles Search value
  //  */
  // const handleSearchChange = (event) => {
  //   console.log(event.target.value, "vall");
  //   // setFilterText(event.target.value);
  //   setSearchVal(event.target.value);
  //   setTimeout(debounceSearchRender(event.target.value), 2000);
  // };

  // /**
  //  * @summary returns the filtered array for search
  //  */
  // const debounceSearchRender = debounce(1000, async (event) => {
  //   if (event) {
  //     // const filteredRows = allData.filter((row) => {
  //     //   const searchText = event.toLowerCase().trim();

  //     //   return (
  //     //     (row.ticket_number &&
  //     //       row.ticket_number.toLowerCase().includes(searchText)) ||
  //     //     (row.vin && row.vin.toLowerCase().includes(searchText))
  //     //   );
  //     // });
  //     // setMappedData(filteredRows);
  //     await getAllTicketsData({
  //       variables: {
  //         oemId: props.oem, limit: totalCount, offset: 0, ticketSearch: {
  //           ticketNumber: event.trim()
  //         }
  //       },
  //     });
  //   } else {
  //     await getAllTicketsData({
  //       variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
  //     });
  //   }
  // });

  /**
   * @summary This handles the row change in pagination
   */
  const handleChangeRowsPerPage = async (event) => {
    setSearchVal("");
    setRowsPerPage(+event.target.value);
    setPage(0);
    setNext(0);
    await getAllTicketsData({
      variables: { oemId: props.oem, limit: event.target.value, offset: 0 },
    });
  };

  /**
   * @summary This closes the form to add VIN and IMEI map
   */
  const handleClose = () => {
    setOpen(false);
    setUpdateOpen(false);
    setAttachedfilenameurl(null);
    setAttachedscreenshoturl(null);
  };

  const handleAddClose = () => {
    setIssue(null);
    setSubject(null);
    setVin(null);
    setcustomerName(null);
    setcustomerEmail(null);
    setSubject(null);
    setpriority(null);
    setOpen(false);
    setCategory(null)
    setAttachedscreenshoturl(null)
    setAttachedfilenameurl(null)
  };

  /**
   * @summary This opens the form to raise tickets
   */
  const handleTickets = () => {
    setOpen(true);
    setAttachedfilenameurl(null);
    setAttachedscreenshoturl(null);
  };

  /**
   * @summary This opens the form to update tickets
   */
  const handleOpen = (id, ticketNumber, status, comment) => {
    setUpdateOpen(true);
    setTicketNumber(ticketNumber);
    setTicketId(id);
    setStatus(status);
    setComment(comment);
    setPreviousStatus(status);
    setPreviousComment(comment);
  };

  /**
   * @summary This opens the form to view issue
   */
  const handleOpenIssue = (
    id,
    ticketNumber,
    status,
    comment,
    subject,
    issue,
    attached_filename_url,
    attached_screenshot_url
  ) => {
    console.log("comment-1---", comment);
    setOpenIssue(true);
    setTicketNumber(ticketNumber);
    setTicketId(id);
    setIssue(issue);
    setAttachedfilenameurl(attached_filename_url);
    setAttachedscreenshoturl(attached_screenshot_url);
    setStatus(status);
    setSubject(subject);
    setComment(comment);
  };

  /**
   * @summary This opens the form to view issue
   */
  const handleCloseIssue = () => {
    setOpenIssue(false);
    setAttachedfilenameurl(null);
    setAttachedscreenshoturl(null);
  };

  const [uploadFile] = useMutation(GET_UPLOAD_URL, {
    errorPolicy: "all"
  })

  /**
   * @summary This uploads the file to bucket
   */
  const onUploadFile = async (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      //console.log(file);
      props.setMessage("uploading")
      props.setNetworkError(false);
      props.setSnackbar(true);
      const fileExtension = file.name.substring(
        file.name.lastIndexOf(".") + 1
      );
      await uploadFile({
        variables: {
          fileExtension,
          bucketName: process.env.REACT_APP_BUCKET_NAME
        },
        onCompleted: async (res) => {
          if (res?.getPublicUploadURL) {
            let url = res?.getPublicUploadURL.publicUploadURL
            setAttachedfilenameurl(res?.getPublicUploadURL.filename);
            await axios.put(url, file,{
                headers: {
                  'x-ms-blob-type': 'BlockBlob',
                },
              }
            );
            props.setSnackbar(false);
          } else {
            props.setMessage("Failed to upload")
            props.setNetworkError(false);
            props.setSnackbar(true);
            handleSnackbarClose()
          }
        }
      })
    } else {
      alert("No file selected");
    }
  };


  const handleSnackbarClose = () => {
    setTimeout(async () => {
      props.setSnackbar(false);
    }, 5000);
  }

  /**
   * @summary This uploads the screenshot to bucket
   */
  const onUploadScreenshot = async (event) => {
    if (event.target.files[0]) {
      let file = event.target.files[0];
      let imageArr = [
        "JPEG",
        "JPG",
        "PNG",
        "GIF",
        "TIFF",
        "PSD",
        "PDF",
        "EPS",
        "AI",
        "INDD",
        "RAW",
      ];
      let ext = /^.+\.([^.]+)$/.exec(file.name);
      console.log(ext);
      let fileExtension = ext[1].toUpperCase();
      if (!imageArr.includes(fileExtension)) {
        props.setMessage("Only image format allowed")
        props.setNetworkError(true);
        props.setSnackbar(true);
        handleSnackbarClose()
        setAttachedscreenshoturl(null);
        event.target.value = ""
      } else {
        //console.log(file);
        props.setMessage("uploading")
        props.setNetworkError(false);
        props.setSnackbar(true);
        const fileExtension = file.name.substring(
          file.name.lastIndexOf(".") + 1
        );
        await uploadFile({
          variables: {
            fileExtension,
            bucketName: process.env.REACT_APP_BUCKET_NAME
          },
          onCompleted: async (res) => {
            if (res?.getPublicUploadURL) {
              let url = res?.getPublicUploadURL.publicUploadURL
              setAttachedscreenshoturl(res?.getPublicUploadURL.filename);
              await axios.put(url, file,{
                  headers: {
                    'x-ms-blob-type': 'BlockBlob',
                  },
                }
              );
              props.setSnackbar(false);
            } else {
              props.setMessage("Failed to upload")
              props.setNetworkError(false);
              props.setSnackbar(true);
              handleSnackbarClose()
            }
          }
        })
      }
    } else {

      setAttachedfilenameurl(null);
      setAttachedscreenshoturl(null);
      alert("No file selected");
    }
  };

  /**
   * @summary Api call to add VIN and IMEI map
   */
  const [
    addCreateTickets,
    { error: maperror, loading: mapLoading, data: response },
  ] = useMutation(ADD_TICKETS, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
    },
    onCompleted: async (ressss) => {
      props.setMessage(ressss.createTicket.message);

      if (ressss.createTicket.code == 200) {
        handleAddClose();
      }

      props.setNetworkError(true);

      props.setSnackbar(true);

      await getAllTicketsData({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
      setTimeout(async () => {
        props.setSnackbar(false);
      }, 5000);
    },
  });

  /**
   * @summary Api call to add VIN and IMEI map
   */
  const [
    addupdateTickets,
    { error: updateerror, loading: updateLoading, data: updateresponse },
  ] = useMutation(UPDATE_TICKETS, {
    onError: ({ graphQLErrors, networkError }) => {
      if (networkError) {
        props.setMessage(`Message: ${networkError}`);
        props.setNetworkError(true);
        props.setSnackbar(true);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          props.setMessage(`Message: ${message}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        });
      }
      setDisabled(false);
    },
    onCompleted: async (ressss) => {
      console.log(ressss);
      props.setMessage(ressss.updateTicketStatus.message);
      if (ressss.updateTicketStatus.code == 200) {
        handleClose();
      }
      props.setNetworkError(true);
      props.setSnackbar(true);
      //setTimeout(props.setSnackbar(false), 5000);
      setSearchVal("");
      setDisabled(false);
      await getAllTicketsData({
        variables: { oemId: props.oem, limit: rowsPerPage, offset: page },
      });
      setTimeout(async () => {
        props.setSnackbar(false);
      }, 5000);
      //setTimeout(props.setSnackbar(false), 5000);
    },
  });

  /**
   * @summary Api call to fetch all mapped Data
   */
  const [getAllTicketsData, { error: error, loading: loading, data: Data }] =
    useLazyQuery(GET_ALL_TICKET_DATA, {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        if (networkError) {
          props.setMessage(`Message: ${networkError}`);
          props.setNetworkError(true);
          props.setSnackbar(true);
        }

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            props.setMessage(`Message: Internal Server Error`);
            props.setNetworkError(true);
            props.setSnackbar(true);
          });
        }
      },
      onCompleted: (result) => {
        if (result.getAllTickets.data) {
          setMappedData(result.getAllTickets.data);
          setAlldata(result.getAllTickets.data);
        }
        if (result.getAllTickets.count) {
          setTotalCount(result.getAllTickets.count);
        }
      },
    });

  /**
   * @summary This call the api to add VIN and IMEI map
   */
  const addTickets = async () => {
    await addCreateTickets({
      variables: {
        oemId: props.oem,
        category: category,
        vin: vin,
        contact_name: customerName,
        contact_email: customerEmail,
        subject: subject,
        issue: issue,
        attached_filename_url: attached_filename_url,
        attached_screenshot_url: attached_screenshot_url,
        priority: priority,
      },
    });
  };

  /**
   * @summary This call the api to add VIN and IMEI map
   */
  const updateTickets = async () => {
    setDisabled(true);
    await addupdateTickets({
      variables: {
        id: ticketId,
        oemId: props.oem,
        status: status,
        comment: comment,
      },
    });
  };

  const [getDownloadUrl] = useLazyQuery(GET_DOWNLOAD_URL)
  /**
   * @summary This uploads the screenshot to bucket
   */
  const onFileDownload = async (filename) => {
    await getDownloadUrl({
      variables: {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        filename: filename,
      },
      onCompleted: async (res) => {
        if (res?.getPublicDownloadURL) {
          const url = res?.getPublicDownloadURL;
          // window.open(response.data.getPublicDownloadURL);
          const result = await axios({
            url: url,
            method: "GET",
            headers: { Accept: "application/vnd.ms-excel" },
            responseType: "blob",
          });
          FileSaver.saveAs(new Blob([result.data]), filename)
        }
      }
    })
  };

  const onDownload = (fileType, filename) => {
    if (fileType == "FILE") {
      onFileDownload(filename);
    } else {
      onFileDownload(filename);
    }
  };

  /**
   * @summary Api call to fetch all vin mapped Data
   */
  const [
    getOEMVinData,
    { loading: vinLoading, error: vinError, data: vinData },
  ] = useLazyQuery(GET_ALL_VIN, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      result.getAllVinImeiMapData.mappedData.rows.map((item) => {
        vinArray.push({ vin: item.vin });
        setOemVin(vinArray);
      });
    },
  });

  useEffect(() => {
    if (props.oem) {
      getAllTicketsData({
        variables: { oemId: props.oem, limit: 20, offset: 0 },
      });
      getAccess({
        variables: {
          featureId: id,
        },
      });

      getOEMVinData({
        variables: {
          oemId: props.oem,
          limit: 2000,
          offset: 0,
        },
      });
    }
    //removed previous snackbar state
    props.setSnackbar(false);
  }, [props.oem]);
  return (
    <React.Fragment>
      <AddTicket
        open={open}
        handleClose={handleAddClose}
        oemVin={oemVin}
        category={category}
        setCategory={setCategory}
        vin={vin}
        setVin={setVin}
        customerName={customerName}
        setcustomerName={setcustomerName}
        customerEmail={customerEmail}
        setcustomerEmail={setcustomerEmail}
        subject={subject}
        setSubject={setSubject}
        issue={issue}
        setIssue={setIssue}
        attached_filename_url={attached_filename_url}
        setAttachedfilenameurl={setAttachedfilenameurl}
        attached_screenshot_url={attached_screenshot_url}
        setAttachedscreenshoturl={setAttachedscreenshoturl}
        priority={priority}
        setpriority={setpriority}
        onUploadFile={onUploadFile}
        onUploadScreenshot={onUploadScreenshot}
        addTickets={addTickets}
        vinLoading={vinLoading}
        snackbar={props.open}
      />
      <UpdateTicket
        open={updateOpen}
        handleClose={handleClose}
        ticketNumber={ticketNumber}
        comment={comment}
        status={status}
        setStatus={setStatus}
        setComment={setComment}
        updateTickets={updateTickets}
        previousStatus={previousStatus}
        previousComment={previousComment}
        disableButton = {disableButton}
      />
      <ViewIssue
        open={openIssue}
        handleClose={handleCloseIssue}
        ticketNumber={ticketNumber}
        issue={issue}
        comment={comment}
        status={status}
        subject={subject}
        attached_filename_url={attached_filename_url}
        attached_screenshot_url={attached_screenshot_url}
        onDownload={onDownload}
      />
      <Grid container spacing={4} style={{ backgroundColor: "#F3F3F3 " }}>
        <Grid item xs={12}>
          <Typography
            style={{
              marginTop: "8px",
              marginBottom: "0px",
            }}
            variant="h4"
          >
            Ticket Details
          </Typography>
        </Grid>
        <Grid container style={{ marginTop: "32px" }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} style={{ paddingLeft: "60px" }}>
                <Grid
                  container
                  style={{
                    backgroundColor: "#FFFFFF",
                    // paddingTop: "20px",
                  }}
                  spacing={4}
                >
                  <Grid item xs={6} style={{ padding: "8px" }}>
                    <TextField
                      placeholder="Search Number"
                      variant="outlined"
                      value={searchVal}
                      onChange={(e) => handleSearchChange(e)}
                      // onClick={handleSearch}
                      style={{ width: "80%", backgroundColor: "#F3F3F3 " }}
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ paddingLeft: "3px" }} />
                          </InputAdornment>
                        ),
                      }}
                      className={classes.textfield}
                    />
                  </Grid>
                  {createPermission && (
                    <Grid item xs={6} style={{ paddingRight: "30px" }}>
                      <Grid
                        container
                        spacing={2}
                        style={{ justifyContent: "end" }}
                      >
                        <Grid item>
                          <ColorButton onClick={handleTickets}>
                            <Typography className={classes.buttonText}>
                              Raise Ticket
                            </Typography>
                          </ColorButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12} style={{ paddingTop: '0px', paddingLeft: '0px', maxHeight: '60vh' }}>
                    {loading ? (
                      <CustomCircularProgress />
                    ) : mappedData.length > 0 ? (
                      <>
                        <Paper>
                          <TableContainer
                            style={{
                              maxHeight: "calc(100vh - 260px)"
                            }}
                          >
                            <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                <TableRow>
                                  {columns.map((columns) => (
                                    <TableCell
                                      align="center"
                                      style={{
                                        minWidth: columns.minWidth,
                                        font: "normal normal 600 15px/20px Nunito Sans",
                                        backgroundColor: "#faf8f7",
                                      }}
                                      className={classes.tableHead}
                                    >
                                      {columns.label}
                                    </TableCell>
                                  ))}
                                  {updatePermission && (
                                    <TableCell
                                      align="center"
                                      style={{
                                        // minWidth: 200,
                                        font: "normal normal 600 15px/20px Nunito Sans",
                                        backgroundColor: "#faf8f7",
                                      }}
                                      className={classes.tableHead}
                                    >
                                      Action
                                    </TableCell>
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {mappedData &&
                                  mappedData.map((row, index) => {
                                    let createdDate = moment
                                      .unix(row.createdOn)
                                      .format("DD-MM-YYYY HH:mm:ss");
                                    return (
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                      >
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {next + (index + 1)}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.ticket_number}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.category}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.vin}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.contact_name}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.contact_email}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.subject}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          <CustomButton
                                            variant="contained"
                                            onClick={() =>
                                              handleOpenIssue(
                                                row.id,
                                                row.ticket_number,
                                                row.status,
                                                row.comment,
                                                row.subject,
                                                row.issue,
                                                row.attached_filename_url,
                                                row.attached_screenshot_url
                                              )
                                            }
                                          >
                                            View Issue
                                          </CustomButton>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {createdDate}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.status}
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          className={classes.tableContent}
                                        >
                                          {row.priority}
                                        </TableCell>
                                        {updatePermission && (
                                          <TableCell
                                            align="center"
                                            className={classes.tableContent}
                                          >
                                            <Grid
                                              container
                                              alignContent="center"
                                              style={{ height: "5px" }}
                                            >
                                              <Grid item>
                                                <Button
                                                  onClick={() =>
                                                    handleOpen(
                                                      row.id,
                                                      row.ticket_number,
                                                      row.status,
                                                      row.comment
                                                    )
                                                  }
                                                >
                                                  <CreateIcon
                                                    style={{
                                                      fill: "#2b2924",
                                                    }}
                                                  />
                                                </Button>
                                              </Grid>
                                            </Grid>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[20, 50, 100]}
                            component="div"
                            rowsPerPage={rowsPerPage}
                            count={totalCount ? totalCount : 0}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Paper>
                      </>
                    ) : (
                      <NoData />
                    )}
                  </Grid >
                </Grid >
              </Grid >
            </Grid >
          </Grid >
        </Grid >
      </Grid >
    </React.Fragment >
  );
}

export default withSnackbar(TicketCreation);
