import { Tab } from "@mui/base";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableHead,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { gql, useLazyQuery } from "@apollo/client";
import { CustomCircularProgress, NoData } from "../../hoc/CustomComponents";
const GET_VIN_IMEI_LOGS = gql`
  query ($oemId: Int!, $limit: Int, $offset: Int, $vin: String!) {
    getLogsByVin(oemId: $oemId, limit: $limit, offset: $offset, vin: $vin) {
      code
      message
      mappedData {
        totalCount
        rows {
          id
          vin
          imei
          vehicleType
          vehicleModel
          fuelType
          createdOn
          status
          lat
          long
          pluscode
          deviceTS
          serverTS
          packetType
          eoltCompletedOn
          firmwareVersion
          vinImeiMappedOn
          engineNumber
        }
      }
    }
  }
`;
const LogsByVin = ({
  open,
  columns,
  allLogs,
  handleVinLogs,
  selectedVin,
  oem,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  let [next, setNext] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [logs, setLogs] = useState();
  const [getVinImeiLogs, { loading: logsLoading, data: campaign }] =
    useLazyQuery(GET_VIN_IMEI_LOGS, {
      fetchPolicy: "network-only",
      onError: ({ graphQLErrors, networkError }) => {
        //   if (networkError) {
        //     props.setMessage(`Message: ${networkError}`);
        //     props.setNetworkError(true);
        //     props.setSnackbar(true);
        //   }
        //   if (graphQLErrors) {
        //     graphQLErrors.forEach(({ message, locations, path }) => {
        //       props.setMessage(`Message: ${message}`);
        //       props.setNetworkError(true);
        //       props.setSnackbar(true);
        //     });
        //   }
      },
      onCompleted: async (res) => {
        console.log(res.getLogsByVin, "res");
        if (res.getLogsByVin?.code == 200) {
          setLogs(res.getLogsByVin.mappedData.rows);
          // setTotalCount(res.getAllVinImeiLogs.mappedData.totalCount);
        }
      },
    });
  useEffect(() => {
    getVinImeiLogs({
      variables: {
        oemId: oem,
        vin: selectedVin,
      },
    });
  }, [oem]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      aria-labelledby="max-width-dialog-title"
      disableEscapeKeyDown={true}
    >
      <DialogTitle>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={9}>
                Vin Logs
              </Grid>
              <Grid item xs={3}>
                <Button style={{ float: "right" }} onClick={handleVinLogs}>
                  <CloseIcon
                    style={{
                      fill: "#2b2924",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {logsLoading ? (
              <CustomCircularProgress />
            ) : logs?.length > 0 ? (
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead stickyHeader aria-label="sticky table">
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          align="center"
                          style={{
                            minWidth: column.minWidth,
                            font: "normal normal 600 15px/20px Nunito Sans",
                            backgroundColor: "#faf8f7",
                          }}
                          className={classes.tableHead}
                        >
                          {column.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs &&
                      logs.map((row, index) => {
                        let createdDate = moment
                          .unix(row.createdOn)
                          .format("DD-MM-YYYY HH:mm:ss");
                        let deviceTS = row.deviceTS
                          ? moment
                              .unix(row.deviceTS)
                              .format("DD-MM-YYYY HH:mm:ss")
                          : null;
                        let serverTS = row.serverTS
                          ? moment
                              .unix(row.serverTS)
                              .format("DD-MM-YYYY HH:mm:ss")
                          : null;
                        let eoltTS = row.eoltCompletedOn
                          ? moment
                              .unix(row.eoltCompletedOn)
                              .format("DD-MM-YYYY HH:mm:ss")
                          : null;
                        let vinImeiMappedOn = row.vinImeiMappedOn
                          ? moment
                              .unix(row.vinImeiMappedOn)
                              .format("DD-MM-YYYY HH:mm:ss")
                          : null;
                        return (
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {next + (index + 1)}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.vin}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.imei}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.engineNumber
                                ? row.engineNumber
                                : "NA"}
                            </TableCell>

                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.status}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {vinImeiMappedOn
                                  ? vinImeiMappedOn
                                  : "NA"}
                            </TableCell>

                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {createdDate}
                            </TableCell>

                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.lat ? row.lat : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.long ? row.long : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.pluscode ? row.pluscode : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {deviceTS ? deviceTS : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {serverTS ? serverTS : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {row.packetType ? row.packetType : "NA"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableContent}
                            >
                              {eoltTS ? eoltTS : "NA"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NoData />
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LogsByVin;
